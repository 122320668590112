<template>
  <div id="users_list">
    <div class="right div--button ma-2">
      Filtered rows: {{ users_length }}
    </div>

    <b-input-group class="mb-3"
                   prepend="Users registered from - to">
      <b-form-input v-model="createdAtRange.from"
                    aria-label="From"
                    placeholder="YYYY-MM-DD"
                    @keyup.enter="filterUsers"/>
      <b-form-input v-model="createdAtRange.to"
                    aria-label="To"
                    placeholder="YYYY-MM-DD"
                    @keyup.enter="filterUsers"/>
      <b-input-group-append>
        <b-button variant="info"
                  @click="filterUsers">
          Filter by date
        </b-button>
      </b-input-group-append>
    </b-input-group>

    <b-input-group class="mb-3"
                   prepend="Display only active users">
      <b-form-radio-group v-model="displayOnlyActive"
                          :options="yesNo"
                          button-variant="outline-secondary"
                          buttons
                          class="w-95"/>
    </b-input-group>

    <b-table :current-page="current_page"
             :fields="fields"
             :items="users"
             :per-page="per_page"
             head-variant="light"
             hover
             outlined
             responsive
             striped>

      <template v-slot:top-row="{fields}">
        <proposal_filter :id="id"
                         v-model="users"
                         :fields="fields"
                         :not_include_key="['actions', 'ordinalNumber']">
        </proposal_filter>
      </template>

      <template v-slot:cell(ordinalNumber)="row">
        <base-column-ordinal-number v-bind="{index: row.index, perPage: per_page, currentPage: current_page}"/>
      </template>

      <template v-slot:cell(actions)="row">
        <base-icon-button-send title="Go to user details"
                               @click="go_to_user(row.item._id.$oid)"/>
      </template>

    </b-table>

    <b_table_footer v-model="is_busy"
                    :currentPage="current_page"
                    :data_length="users_length"
                    :perPage="per_page"
                    @change_page="change_page">
    </b_table_footer>
  </div>
</template>

<script>
  import BaseIconButtonSend from '@/components/baseComponents/baseIcons/BaseIconButtonSend';
  import b_table_footer from '@/components/b_table_footer';
  import proposal_filter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import moment from 'moment';
  import yesNo from '@/json/radioButtonYesNo';

  export default {
    name: 'users_list',
    components: {
      BaseColumnOrdinalNumber,
      BaseIconButtonSend,
      proposal_filter,
      b_table_footer,
    },
    props: {
      fields: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        is_busy: false,
        allUsers: [],
        users: [],
        displayOnlyActive: false,
        yesNo,
        createdAtRange: { from: '', to: '' },
        current_page: 1,
        per_page: 20,
        id: 1,
      };
    },
    computed: {
      users_length() {
        return this.users.length;
      },
    },
    watch: {
      displayOnlyActive: {
        handler() {
          this.filterUsers();
        },
      },
    },
    created() {
      this.load_data();
    },
    methods: {
      load_data() {
        this.toggle_busy();
        this.axios.get('/users/list')
          .then((response) => {
            this.users = response.data;
            this.users = this.users.map(
              (user) => ({
                ...user,
                created_at: moment(user.created_at, 'YYYY-MM-DD'),
              }),
            );
            this.allUsers = [...this.users];
            this.$emit('setup_proposal', this.users);
            this.toggle_busy();
          });
      },
      setFilterDatesIfEmpty() {
        const from = this.createdAtRange.from === '' ? moment('2018-01-01') : this.createdAtRange.from;
        const to = this.createdAtRange.to === '' ? moment() : this.createdAtRange.to;
        return ([from, to]);
      },
      filterUsersByDate(from, to) {
        return this.allUsers.filter(
          (user) => user.created_at.isBetween(from, to, null, []),
        );
      },
      filterUsersIsActive(users) {
        return users.filter(
          (user) => user.is_active,
        );
      },
      filterUsers() {
        const [from, to] = this.setFilterDatesIfEmpty();
        const usersWithinDateRange = this.filterUsersByDate(from, to);
        let filteredUsers = usersWithinDateRange;
        if (this.displayOnlyActive) {
          filteredUsers = this.filterUsersIsActive(usersWithinDateRange);
        }
        this.$set(this, 'users', filteredUsers);
        this.$emit('setup-proposal-user-filters', this.users);
      },
      toggle_busy() {
        this.is_busy = !this.is_busy;
      },
      go_to_user(user_id) {
        this.$router.push(`/operational/users/${user_id}/0`);
      },
      change_page(val) {
        this.current_page = val;
      },
    },
  };
</script>

<style scoped>
</style>
